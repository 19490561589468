<template>
  <div class="aboutNFT">
    <div class="aboutNFTTitle">
      关于NFT
      <img src="../assets/back.png" @click="goBack()" alt="" />
    </div>
    <div class="aboutCon">
      <div class="partOne">
        <img src="../assets/smsNTF.png" alt="" class="partOne-title" />
        <div class="partOne-List">
          <div class="partOne-item" v-for="item in nftData.nft" :key="item.id">
            <img :src="item.image" alt="" />
            <div>{{ item.title }}</div>
            <p>{{ item.desc }}</p>
          </div>
        </div>
      </div>
      <div class="partTwo">
        <img src="../assets/ptjj.png" alt="" class="partTwo-title" />
        <div class="partTwo-div" v-html="nftData.desc_content"></div>
      </div>
      <div class="partThree">
        <img src="../assets/jzms.png" alt="" class="partThree-title" />
        <div class="partTwo-div" v-html="nftData.description_content"></div>
      </div>
      <div class="partThree">
        <img src="../assets/tdjs.png" alt="" class="partThree-title" />
        <div class="partTwo-div" v-html="nftData.team_content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { goNft } from "../api/person.js";

export default {
  setup() {},
  data() {
    return {
      nftData: {},
    };
  },
  mounted() {
    this.getNftData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getNftData() {
      goNft().then((res) => {
        console.log(res.data);
        this.nftData = res.data;
      });
    },
  },
};
</script>
<style lang="scss">
.aboutNFT {
  .partTwo-div {
    p {
      line-height: 12px;
    }
  }
}
</style>
<style lang="scss" scoped>
.aboutNFT {
  .aboutNFTTitle {
    height: 40px;
    background: #ffffff;
    position: relative;
    font-size: 15px;
    color: #333333;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 18px;
      width: 16px;
      margin: auto;
    }
  }
  .aboutCon {
    padding: 0 15px;
    padding-bottom: 20px;
    .partOne {
      .partOne-title {
        width: 121px;
        margin: 0 auto;
        margin-top: 25px;
      }
      .partOne-List {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        .partOne-item {
          width: 94px;
          height: 200px;
          padding: 15px 7px;
          border-radius: 10px;
          background-color: #ffffff;
          img {
            width: 50px;
            margin: 0 auto;
            height: 50px;
          }
          div {
            font-size: 13px;
            margin-top: 15px;
            margin-bottom: 10px;
            color: #333333;
            text-align: center;
            font-weight: bold;
          }
          p {
            font-size: 11px;
            color: #333333;
          }
        }
      }
    }
    .partTwo {
      .partTwo-title {
        width: 136px;
        margin: 0 auto;
        margin-top: 25px;
        margin-bottom: 10px;
      }
    }
    .partThree {
      .partThree-title {
        width: 136px;
        margin: 0 auto;
        margin-top: 25px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>